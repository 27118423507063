
import { Component } from "vue-property-decorator";
import ViewMixin from "@/mixins/Crud/ViewMixin";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import { Tent } from "@/config/Modules";
import { mixins } from "vue-class-component";
import { ON_REMOVE_SUCCESS } from "@/config/Events";
import { bus } from "@/init/_bus";
import { TentStoreMixin } from "@/mixins/Stores/TentStoreMixin";

@Component({
  components: { DefaultLayout },
  mixins: [ViewMixin],
})
export default class TentView extends mixins(ViewMixin, TentStoreMixin) {
  public name = "TentView";

  protected resource = Tent.resource;
  protected descriptionField = "plant";
  protected redirectRouteNameOnDeleteSuccess = "TentList";

  protected onAdd(): void {
    this.$router.push({ name: "TentCreate" });
  }

  public async removeAction(options: any): Promise<any> {
    return this.delTentItemAction(options);
  }

  protected onRemoveSuccess(response: any, resourceName: string): void {
    bus.$emit(ON_REMOVE_SUCCESS, { resource: resourceName });
  }

  protected get deleteUrl() {
    const deleteUrl = this.$route?.params?.id
      ? [this.resource, this.$route?.params?.id].join("/")
      : null;

    console.debug("TentView deleteUrl", deleteUrl, this.$route.params);

    return deleteUrl;
  }
}
