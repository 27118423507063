import { render, staticRenderFns } from "./TentView.vue?vue&type=template&id=170e1a27&scoped=true"
import script from "./TentView.vue?vue&type=script&lang=ts"
export * from "./TentView.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "170e1a27",
  null
  
)

export default component.exports